import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import authors from '../util/authors'
import { Button,CardText, CardBody, CardTitle, Row } from 'reactstrap'
import JohnImage from '../images/john.png'
import { slugify } from '../util/utilityFunctions'
import {Im} from '../components/PageTitle/styles'
import john from '../images/homotopia.png'
import {PPost} from '../components/PostExcerpt/styles'
import {Ptd, Ptd2} from '../components/PageTitle/styles'

const TeamPage = () => (
  <>
  <Im src={john} alt="Cosmic"  />
  <Ptd2 >
          </Ptd2>
  <Layout pageTitle="Bruh">
 
  <PPost style={{ minHeight: '100%'}} >
<div class="team">
    <SEO title="Our Team" keywords={[`gatsby`, `application`, `react`]} />
    <Row className="mb-4">
    
        <img src={JohnImage}   style={{ maxWidth: '100%'}} alt="Armando" />
      
      
      <div className="author-social-links text-center">
          <CardBody>
            <CardTitle><h1>{authors[0].name}</h1></CardTitle>
            <CardText>{authors[0].bio}</CardText>
            <Button
              classname="text-uppercase"
              margin='2em'

              color="primary"
              href={`/author/${slugify(authors[0].name)}`}
            > 
              Ver posts
            </Button>
    
            <br />
            <br />
           
           
          
            <ul>
            <br />
              <li>
                <a
                  href={authors[0].facebook}
                  targe="_blank"
                  rel="noopener noreferrer"
                  className="facebook"
                >

                  <i className="fab fa-facebook-f fa-lg" />
                </a>
              </li>
              <li>
                

                <a
                  href={authors[0].twitter}
                  targe="_blank"
                  rel="noopener noreferrer"
                  className="twitter"
                >
                  <i className="fab fa-twitter fa-lg" />
                </a>
              </li>

              <li>
                <a
                  href={authors[0].instagram}
                  targe="_blank"
                  rel="noopener noreferrer"
                  className="instagram"
                >
                  <i className="fab fa-instagram fa-lg" />
                </a>
              </li>
              
            </ul>
          </CardBody> 
          
       
      </div>
      
    </Row>
    </div>
    </PPost>
  </Layout>
  
  </>

)

export default TeamPage
